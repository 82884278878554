import {Layout} from "../components/Layout";
import React from 'react';
import {PageMainColumnWrapper} from "../components/PageMainColumnWrapper";
import {Breadcrumbs} from "../components/Breadcrumbs";
import {BlockTitle} from "../components/BlockTitle";
import {BlogPostMediaObject} from "../components/BlogPostMediaObject";
import {ContactPersonBlock} from "../components/ContactPersonBlock";
import {ArticlesListWithFilter} from "../components/ArticlesListWithFilter";
import {graphql} from "gatsby";
import {MarkdownContent} from "../components/MarkdownContent";
import {rwdImages} from "../functions/rwdImages";
import * as styles from './blog.module.scss';
import {useIntl} from 'gatsby-plugin-react-intl';
import {createSeoData} from "../functions/createSeoData";

export const query = graphql`query BlogPage($locale: String!, $offset: Int!, $lang: String!) {
    posts: allContentfulBlogPost(
        filter: {node_locale: {eq: $locale}, language: {eq: $lang}}
        sort: {fields: [publishDate], order: [DESC]}
        limit: 6
        skip: $offset
    ) {
        edges {
            node {
                title
                slug
                isFeatured
                category
                createdAt
                language
                author {
                    name
                }
                intro {
                    childMarkdownRemark {
                        html
                    }
                }
                image {
                    localFile {
                        url
                        childImageSharp {
                            gatsbyImageData(width: 630, height: 530, placeholder: BLURRED, layout: FIXED)
                        }
                    }
                }
            }
        }
    }
    page: contentfulBlogPage(node_locale: {eq: $locale}) {
        title
        contactPersonTitle
        contactPersonContent {
            childMarkdownRemark {
                html
            }
        }
        contactPerson {
            name
            position
            email
            phone
            bigImage {
                localFile {
                    childImageSharp {
                        gatsbyImageData(
                            width: 680
                            height: 1000
                            placeholder: BLURRED
                            transformOptions: {cropFocus: NORTH}
                            layout: FIXED
                        )
                    }
                }
            }
            mobileBigImage: bigImage {
                localFile {
                    childImageSharp {
                        gatsbyImageData(
                            width: 300
                            height: 400
                            placeholder: BLURRED
                            transformOptions: {cropFocus: NORTH}
                            layout: FIXED
                        )
                    }
                }
            }
        }
        seo {
            ...Seo
        }
    }
}
`;

export default function BlogPage({data, pageContext}) {
    const intl = useIntl();

    const articles = data.posts.edges;

    const totalPages = pageContext.pages;
    const currentPage = pageContext.page;

    const nextLink = totalPages > currentPage ? `/blog/page-${currentPage + 1}` : undefined;
    const previousLink = currentPage > 2 ? `/blog/page-${currentPage - 1}` : `/blog`;

    return (
        <Layout services={pageContext.services}
                oldServices={pageContext.oldServices}
                linkableSubServices={pageContext.linkableSubServices}
                jobsCount={pageContext.jobsCount}
                seo={createSeoData(data.page.seo)}
                title={intl.formatMessage({id: 'menu.blog'})}
                defaultHrefLang={pageContext.lang}
                lang={pageContext.lang}
                companyData={pageContext.companyData}
                hrefLangs={[pageContext.lang]}>
            <PageMainColumnWrapper>
                <Breadcrumbs className={styles.breadcrumbs}>
                    <Breadcrumbs.Item to="/">{intl.formatMessage({id: 'menu.home'})}</Breadcrumbs.Item>
                    <Breadcrumbs.Item>{data.page.title}</Breadcrumbs.Item>
                </Breadcrumbs>
                <BlockTitle className={styles.title}>{data.page.title}</BlockTitle>
                <ArticlesListWithFilter nextLink={nextLink} previousLink={previousLink}>
                    {articles.map((x, index) => {
                        return <BlogPostMediaObject
                            title={x.node.title}
                            key={index}
                            slug={x.node.slug}
                            category={x.node.category}
                            language={x.node.language}
                            author={x.node.author.name}
                            description={<MarkdownContent content={x.node.intro}/>}
                            image={x.node.image.localFile}
                        />
                    })}
                </ArticlesListWithFilter>
            </PageMainColumnWrapper>
            <ContactPersonBlock
                title={data.page.contactPersonTitle}
                image={data.page.contactPerson.bigImage.localFile}
                mobileImage={data.page.contactPerson.mobileBigImage.localFile}
                person={{
                    phone: data.page.contactPerson.phone,
                    email: data.page.contactPerson.email,
                    name: data.page.contactPerson.name,
                    position: data.page.contactPerson.position
                }}
            >
                <MarkdownContent content={data.page.contactPersonContent}/>
            </ContactPersonBlock>

        </Layout>
    );
}
